:root {
    --ion-color-primary: #17c5f8;
    --ion-color-primary-rgb: 23, 197, 248;
    --ion-color-primary-contrast: #000000;
    --ion-color-primary-contrast-rgb: 0, 0, 0;
    --ion-color-primary-shade: #14adda;
    --ion-color-primary-tint: #2ecbf9;
    --ion-color-secondary: #5260ff;
    --ion-color-secondary-rgb: 82, 96, 255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #4854e0;
    --ion-color-secondary-tint: #6370ff;
    --ion-color-tertiary: #0065eb;
    --ion-color-tertiary-rgb: 0, 101, 235;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #0059cf;
    --ion-color-tertiary-tint: #1a74ed;
    --ion-color-success: #5fd416;
    --ion-color-success-rgb: 95, 212, 22;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #54bb13;
    --ion-color-success-tint: #6fd82d;
    --ion-color-warning: #feca04;
    --ion-color-warning-rgb: 254, 202, 4;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0b204;
    --ion-color-warning-tint: #fecf1d;
    --ion-color-danger: #eb445a;
    --ion-color-danger-rgb: 235, 68, 90;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #cf3c4f;
    --ion-color-danger-tint: #ed576b;
    --ion-color-medium: #303030;
    --ion-color-medium-rgb: 48, 48, 48;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #2a2a2a;
    --ion-color-medium-tint: #454545;
    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 245, 248;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;
    --ion-color-dark: #000000;
    --ion-color-dark-rgb: 0, 0, 0;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #000000;
    --ion-color-dark-tint: #1a1a1a;
    --bg-shape-color: linear-gradient(90deg, #414850, #131720);
    --height-top-img-header: 120px;
    --height-segment-header: 80px;
}

@media (prefers-color-scheme: light) {
    /*
   * Dark Colors
   * -------------------------------------------
   */
    body {
        --ion-color-primary: #17c5f8;
        --ion-color-primary-rgb: 23, 197, 248;
        --ion-color-primary-contrast: #000000;
        --ion-color-primary-contrast-rgb: 0, 0, 0;
        --ion-color-primary-shade: #14adda;
        --ion-color-primary-tint: #2ecbf9;
        --ion-color-secondary: #5260ff;
        --ion-color-secondary-rgb: 82, 96, 255;
        --ion-color-secondary-contrast: #ffffff;
        --ion-color-secondary-contrast-rgb: 255, 255, 255;
        --ion-color-secondary-shade: #4854e0;
        --ion-color-secondary-tint: #6370ff;
        --ion-color-tertiary: #0065eb;
        --ion-color-tertiary-rgb: 0, 101, 235;
        --ion-color-tertiary-contrast: #ffffff;
        --ion-color-tertiary-contrast-rgb: 255, 255, 255;
        --ion-color-tertiary-shade: #0059cf;
        --ion-color-tertiary-tint: #1a74ed;
        --ion-color-success: #5fd416;
        --ion-color-success-rgb: 95, 212, 22;
        --ion-color-success-contrast: #000000;
        --ion-color-success-contrast-rgb: 0, 0, 0;
        --ion-color-success-shade: #54bb13;
        --ion-color-success-tint: #6fd82d;
        --ion-color-warning: #feca04;
        --ion-color-warning-rgb: 254, 202, 4;
        --ion-color-warning-contrast: #000000;
        --ion-color-warning-contrast-rgb: 0, 0, 0;
        --ion-color-warning-shade: #e0b204;
        --ion-color-warning-tint: #fecf1d;
        --ion-color-danger: #eb445a;
        --ion-color-danger-rgb: 235, 68, 90;
        --ion-color-danger-contrast: #ffffff;
        --ion-color-danger-contrast-rgb: 255, 255, 255;
        --ion-color-danger-shade: #cf3c4f;
        --ion-color-danger-tint: #ed576b;
        --ion-color-medium: #303030;
        --ion-color-medium-rgb: 48, 48, 48;
        --ion-color-medium-contrast: #ffffff;
        --ion-color-medium-contrast-rgb: 255, 255, 255;
        --ion-color-medium-shade: #2a2a2a;
        --ion-color-medium-tint: #454545;
        --ion-color-light: #f4f5f8;
        --ion-color-light-rgb: 244, 245, 248;
        --ion-color-light-contrast: #000000;
        --ion-color-light-contrast-rgb: 0, 0, 0;
        --ion-color-light-shade: #d7d8da;
        --ion-color-light-tint: #f5f6f9;
        --ion-color-dark: #000000;
        --ion-color-dark-rgb: 0, 0, 0;
        --ion-color-dark-contrast: #ffffff;
        --ion-color-dark-contrast-rgb: 255, 255, 255;
        --ion-color-dark-shade: #000000;
        --ion-color-dark-tint: #1a1a1a;
        --bg-shape-color: linear-gradient(90deg, #414850, #131720);
        --height-top-img-header: 40px;
        --height-top-img-header: 120px;
    }
    /*
   * iOS Dark Theme
   * -------------------------------------------
   */
    .ios body {
        --ion-background-color: #000000;
        --ion-background-color-rgb: 0, 0, 0;
        --ion-text-color: #ffffff;
        --ion-text-color-rgb: 255, 255, 255;
        --ion-color-step-50: #0d0d0d;
        --ion-color-step-100: #1a1a1a;
        --ion-color-step-150: #262626;
        --ion-color-step-200: #333333;
        --ion-color-step-250: #404040;
        --ion-color-step-300: #4d4d4d;
        --ion-color-step-350: #595959;
        --ion-color-step-400: #666666;
        --ion-color-step-450: #737373;
        --ion-color-step-500: #808080;
        --ion-color-step-550: #8c8c8c;
        --ion-color-step-600: #999999;
        --ion-color-step-650: #a6a6a6;
        --ion-color-step-700: #b3b3b3;
        --ion-color-step-750: #bfbfbf;
        --ion-color-step-800: #cccccc;
        --ion-color-step-850: #d9d9d9;
        --ion-color-step-900: #e6e6e6;
        --ion-color-step-950: #f2f2f2;
        /*
    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
  */
    }
    .ios ion-modal {
        --ion-background-color: var(--ion-color-step-100);
        --ion-toolbar-background: var(--ion-color-step-150);
        --ion-toolbar-border-color: var(--ion-color-step-250);
    }
    .ios ion-content {
        --ion-background-color: var(--ion-color-light);
        --ion-safe-area-top: 30px !important;
    }
    .ios ion-header {
        --ion-background-color: var(--ion-color-light);
        --ion-safe-area-top: 50px !important;
    }
    /*
   * Material Design Dark Theme
   * -------------------------------------------
   */
    .md body {
        --ion-background-color: #121212;
        --ion-background-color-rgb: 18, 18, 18;
        --ion-text-color: #ffffff;
        --ion-text-color-rgb: 255, 255, 255;
        --ion-border-color: #222222;
        --ion-color-step-50: #1e1e1e;
        --ion-color-step-100: #2a2a2a;
        --ion-color-step-150: #363636;
        --ion-color-step-200: #414141;
        --ion-color-step-250: #4d4d4d;
        --ion-color-step-300: #595959;
        --ion-color-step-350: #656565;
        --ion-color-step-400: #717171;
        --ion-color-step-450: #7d7d7d;
        --ion-color-step-500: #898989;
        --ion-color-step-550: #949494;
        --ion-color-step-600: #a0a0a0;
        --ion-color-step-650: #acacac;
        --ion-color-step-700: #b8b8b8;
        --ion-color-step-750: #c4c4c4;
        --ion-color-step-800: #d0d0d0;
        --ion-color-step-850: #dbdbdb;
        --ion-color-step-900: #e7e7e7;
        --ion-color-step-950: #f3f3f3;
        --ion-item-background: #1e1e1e;
        --ion-toolbar-background: #1f1f1f;
        --ion-tab-bar-background: #1f1f1f;
        --ion-card-background: #1e1e1e;
        --bg-shape-color: linear-gradient(90deg, var(--ion-color-tertiary), var(--ion-color-warning));
    }
}